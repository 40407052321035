<div class="fullSize flexTopCenter">
  <form
    *ngIf="!submitted"
    class="flexTopCenter flexColumn"
    (ngSubmit)="onSubmit(contactForm)"
    #contactForm="ngForm"
  >
    <mat-form-field class="fullWidth" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput name="name" ngModel required #name="ngModel" />
    </mat-form-field>
    <mat-form-field class="fullWidth" appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput name="email" email ngModel required #email="ngModel" />
    </mat-form-field>
    <mat-form-field class="fullWidth" appearance="fill">
      <mat-label>Message</mat-label>
      <textarea
        matInput
        name="messages"
        ngModel
        required
        #messages="ngModel"
        rows="8"
      ></textarea>
    </mat-form-field>
    <div class="fullWidth flexTopCenter">
      <button type="submit" mat-raised-button color="primary">Submit</button>
    </div>
  </form>
  <div *ngIf="submitted" class="fullSize flexCenter">
    <p>
      Thank you for your request! It has been received and we will review it as
      soon as possible.
    </p>
  </div>
</div>
