<div class="fullSize">
  <p>
    Welcome to the mobile application created by Medic Now Solutions
    specifically for you and your colleagues.
  </p>

  <p>
    Medic Now Solutions was founded by first-responders for first-responders,
    and we are honored to have the opportunity to serve you.
  </p>

  <p>
    At Medic Now Solutions we are committed to offering cutting-edge services to
    providers, resulting in exceptional patient care delivered with consistently
    high reliability.​
  </p>
  ​
  <p>
    With the information you are now holding in the palm of your hand, you have
    a resource that will increase your confidence, reduce stress, improve the
    quality of care you provide and help you navigate your department’s
    protocols and procedures.​
  </p>
  ​
  <p>However, our mobile application is only the beginning.</p>

  <p>
    We want to not only equip you with the tools and knowledge you need for the
    call you’ve answered, but also with the confidence and support you deserve.
  </p>

  <p>Our goal is to make life better for the patient and the provider.</p>

  <p>
    Please visit us at
    <a href="https://www.MedicNowSolutions.com" target="_blank"
      >www.MedicNowSolutions.com</a
    >
    to learn more about the services we provide.
  </p>
</div>
