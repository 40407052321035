import { Component, OnInit } from "@angular/core";
import { FunctionalityService } from "mn-common";
import { ResourceContributionComponent } from "resource-contribution";

@Component({
  selector: "app-administrative",
  templateUrl: "./administrative.component.html",
  styleUrls: ["./administrative.component.scss"],
})
export class AdministrativeComponent implements OnInit {
  tiles = ResourceContributionComponent.tiles;

  constructor(public functionalityService: FunctionalityService) {}

  ngOnInit(): void {}

  includeAuthentication(): boolean {
    return (
      this.functionalityService.includeAuthentication().toLowerCase() === "true"
    );
  }
}
