import { Component, OnInit } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-version",
  templateUrl: "./version.component.html",
  styleUrls: ["./version.component.scss"],
})
export class VersionComponent implements OnInit {
  env = environment;

  version = "Not Available";
  timestamp = "Not Available";
  userAgent = "Not Available";

  isNewVersionAvailable = false;
  isLooking = false;
  newVersion = "";
  postUpdateRequestMessage = "";

  constructor(public updates: SwUpdate) {}

  ngOnInit(): void {
    if (this.env != null) {
      this.version = this.env.appVersion;
      this.timestamp = this.env.timeStamp;
    }
    this.userAgent = window.navigator.userAgent;
    this.postUpdateRequestMessage = "";
  }

  ngOnDestroy() {}

  checkForUpdate(): void {
    this.isLooking = true;
    this.updates.checkForUpdate().then(() => {
      this.isLooking = false;
    });
    this.postUpdateRequestMessage =
      "Checking for new updates to the app. It will be installed automatically if one is found.";
  }
}
