export const environment = {
  appVersion: require("../../package.json").version,
  production: true,
  baseHref: "787f8e9b-99b9-4174-bbad-c70838844137/",
  timeStamp: "05/10/2024 05:20:09 PM EDT",
  clientId: "08GNjhaXDhy09UvhQFOjmSaEOWBcbzaH",
  connection: "accc",
  organization: "accc",
  includeKingLtd: "false",
  includePediaccurate: "false",
  includeAuthentication: "true",
  firebase: {
    appId: "1:612759992770:web:f875e2825054db4102be5b",
    apiKey: "AIzaSyDeQT11lZjAeycPzBW3rryIra3e_z15Yfw",
    authDomain: "mypushsupport.firebaseapp.com",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "mypushsupport",
    storageBucket: "mypushsupport.appspot.com",
    messagingSenderId: "612759992770",
  },
};
