import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { FunctionalityService } from "mn-common";
import { HomeLinksContributionComponent } from "home-links-contribution";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  showScreen = false;
  tiles;

  constructor(
    private functionalityService: FunctionalityService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this.tiles = HomeLinksContributionComponent.tiles;
  }
}
