<div class="fullSize">
  <p>
    The information contained in this application is intended to serve only as a
    reference and guide. Clinicians are cautioned to refer to their official
    state, local and departmental protocols for treatment of the adult and
    pediatric patients to confirm the information contained within this
    application. Medic Now Solutions assumes no liability regarding the
    accuracy, completeness or implementation of the information contained within
    this application.
  </p>
</div>
