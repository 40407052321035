<mat-toolbar
  *ngIf="data.mobileType === 'android'"
  color="primary"
  class="android-prompt"
>
  <button mat-button (click)="installPwa()">Add to Home screen</button>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
  <button class="close-ios-inv" mat-button color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div>
    To install this web app on your device tap the Menu button and then 'Add to
    Home screen' button
  </div>
  <div class="fullWidth flexCenter padding_top">
    <div class="cell_div flexCenter">
      <img class="cell_image" src="./assets/icons/prompt/ios-menu-btn.png" />
    </div>
    <mat-icon>navigate_next</mat-icon>
    <div class="cell_div flexCenter">
      <img class="cell_image" src="./assets/icons/prompt/ios-add-btn.png" />
    </div>
  </div>
</div>
