<div class="fullSize">
  <div class="fullSize flexTopCenter">
    <mat-grid-list cols="3" rowHeight="1:1">
      <ng-container *ngFor="let tile of tiles">
        <mat-grid-tile>
          <div class="fullSize flexColumn flexCenter">
            <div class="restrictSize">
              <ng-container *ngIf="!tile.useRouterLink">
                <a
                  class="cell_a image_container"
                  [href]="[tile.link]"
                  target="_blank"
                >
                  <img [src]="tile.iconSrc" [alt]="tile.iconAlt" />
                </a>
              </ng-container>
              <ng-container *ngIf="tile.useRouterLink">
                <a class="cell_a image_container" [routerLink]="[tile.link]">
                  <img [src]="tile.iconSrc" [alt]="tile.iconAlt" />
                </a>
              </ng-container>
            </div>
            <div>
              <ng-container *ngIf="!tile.useRouterLink">
                <a
                  class="cell_a image_container"
                  [href]="[tile.link]"
                  target="_blank"
                >
                  <span class="icon_label_text">{{ tile.iconAlt }}</span>
                </a>
              </ng-container>
              <ng-container *ngIf="tile.useRouterLink">
                <a class="cell_a image_container" [routerLink]="[tile.link]">
                  <span class="icon_label_text">{{ tile.iconAlt }}</span>
                </a>
              </ng-container>
            </div>
          </div>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>
  </div>
</div>
