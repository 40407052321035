import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { BaseHrefService } from "../../../services/base-href.service";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public baseHrefService: BaseHrefService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {}

  logout(): void {
    var loggedOut = this.baseHrefService.baseHref().endsWith("/")
      ? "logged-out"
      : "/logged-out";
    var rt =
      this.doc.location.origin + this.baseHrefService.baseHref() + loggedOut;
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }
}
