import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class BaseHrefService {
  constructor() {}

  public baseHref(): string {
    return environment.baseHref === ""
      ? environment.baseHref
      : `/${environment.baseHref}`;
  }
}
