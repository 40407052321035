import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SideNavigatorService {
  hideSideNav: boolean = true;

  constructor() {}

  toggleSideNavigator(): void {
    this.hideSideNav = !this.hideSideNav;
  }

  getToggleClass(): String {
    if (!this.hideSideNav) {
      return "sidebar-visible";
    } else {
      return "sidebar-hidden";
    }
  }
}
