<div class="fullSize flexCenter flexColumn">
  <div class="textDiv fullWidth">
    <p class="fullWidth">Welcome to MedicNow.</p>
    <!-- <div class="textDiv">
    <p class="fullWidth">Dear user,</p>
    <p class="fullWidth">
      We trust that you have found great value in the MedicNow application and
      experienced the benefits of using this reliable tool.
    </p>
    <p class="fullWidth">
      We are dedicated to supporting all of you on the front lines of
      pre-hospital, emergency and critical care.
    </p>
    <p class="fullWidth">
      For continued access to the application, please contact your department
      manager.
    </p>
  </div> -->
    <div class="fullWidth flexCenter">
      <app-login class="fullWidth alignTextCenter"></app-login>
    </div>
  </div>
</div>
