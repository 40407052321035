<div class="topLevel" #topLevel id="topLevel">
  <ngx-spinner></ngx-spinner>
  <div class="backgroundGradientContainer"></div>
  <div class="topActiveLevel">
    <app-header></app-header>
    <!-- app-body></app-body -->
    <div #headerBuffer id="headerBuffer" class="headerBuffer"></div>
    <div class="bodyMNUnderlay"></div>
    <div class="body" #body>
      <app-sidebar></app-sidebar>
      <router-outlet></router-outlet>
    </div>
    <div class="footerBuffer"></div>
    <app-footer class="fullWidth"> </app-footer>
  </div>
</div>
