<div class="fullSize flexCenter">
  <div class="fullWidth"></div>
  <button
    mat-icon-button
    [ngStyle]="{ color: '#dddfe3' }"
    [routerLink]="['/home']"
  >
    <mat-icon>home</mat-icon>
  </button>
  <div class="fullWidth"></div>
</div>
