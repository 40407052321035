import { Component, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { FunctionalityService } from "mn-common";
import { SideNavigatorService } from "../../services/side-navigator.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  userFirstName;

  constructor(
    public auth: AuthService,
    public sideNavigatorService: SideNavigatorService,
    public functionalityService: FunctionalityService
  ) {}

  subscription = null;
  ngOnInit(): void {
    this.subscription = this.auth.user$.subscribe((user) => {
      console.log(user);
      if (user) {
        this.userFirstName = user.given_name;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
