import { Injectable } from "@angular/core";
import { FunctionalityService } from "mn-common";
import { environment } from "../../environments/environment";

@Injectable()
//{providedIn: "root",}
export class EnvironmentFunctionalityService implements FunctionalityService {
  constructor() {}

  public includeKingLtd(): string {
    return environment.includeKingLtd;
  }

  public includePediaccurate(): string {
    return environment.includePediaccurate;
  }

  public includeAuthentication(): string {
    return environment.includeAuthentication;
  }
}
