<div class="fullSize flexTopCenter">
  <mat-grid-list cols="3" rowHeight="1:1">
    <ng-container *ngFor="let tile of tiles">
      <mat-grid-tile>
        <div class="fullSize flexColumn flexCenter">
          <div class="restrictSize">
            <a
              class="image_container"
              [routerLink]="[tile.routerLink]"
              [innerHtml]="tile.imageInnerHtml | safeHtml"
            >
            </a>
          </div>
          <div>
            <a
              class="cell_a"
              [routerLink]="tile.routerLink"
              [innerHtml]="tile.labelInnerHtml | safeHtml"
            ></a>
          </div>
        </div>
      </mat-grid-tile>
    </ng-container>
    <mat-grid-tile>
      <div class="fullSize flexColumn flexCenter">
        <div class="restrictSize">
          <a class="image_container" [routerLink]="['/med-calc']">
            <img src="assets/icons/main/calc_icon.png" alt="Med Calc" />
          </a>
        </div>
        <div>
          <a class="cell_a" [routerLink]="['/med-calc']"
            ><span class="icon_label_text">Calculators</span></a
          >
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="fullSize flexColumn flexCenter">
        <div class="restrictSize">
          <a class="image_container" [routerLink]="['/contacts']">
            <img src="assets/icons/main/contacts_icon.png" alt="Contacts" />
          </a>
        </div>
        <div>
          <a class="cell_a" [routerLink]="['/contacts']"
            ><span class="icon_label_text">Contacts</span></a
          >
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="fullSize flexColumn flexCenter">
        <div class="restrictSize">
          <a class="image_container" [routerLink]="['/administrative']">
            <img
              src="assets/icons/main/administrative_icon.png"
              alt="Administrative"
            />
          </a>
        </div>
        <div>
          <a class="cell_a" [routerLink]="['/administrative']"
            ><span class="icon_label_text">Admin</span></a
          >
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
