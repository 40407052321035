<nav
  id="sidebar"
  class="navbar-dark bg-dark"
  [ngClass]="{ hidden: sideNavigatorService.hideSideNav }"
>
  <div
    class="paddingLT"
    *ngIf="
      functionalityService.includeAuthentication().toLowerCase() === 'true' &&
      (auth.isAuthenticated$ | async)
    "
  >
    <span>Hello {{ userFirstName }}</span>
  </div>
  <div
    class="paddingLT"
    *ngIf="
      functionalityService.includeAuthentication().toLowerCase() === 'true' &&
      !(auth.isAuthenticated$ | async)
    "
  >
    <span>You are not logged in</span>
  </div>
  <ul class="navbar-nav">
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/home']">Home</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/about']">About Us</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/support']">Contact Us</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/disclaimer']">Disclaimer</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/release-notes']">Release Notes</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/version']">Version</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/push-support']">Push Support</a>
    </li>
    <li
      class="nav-item active"
      *ngIf="
        functionalityService.includeAuthentication().toLowerCase() === 'true' &&
        (auth.isAuthenticated$ | async)
      "
    >
      <app-logout></app-logout>
    </li>
    <li
      class="nav-item active"
      *ngIf="
        functionalityService.includeAuthentication().toLowerCase() === 'true' &&
        !(auth.isAuthenticated$ | async)
      "
    >
      <app-login></app-login>
    </li>
  </ul>
</nav>
