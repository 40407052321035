<div class="fullSize flexTopCenter">
  <mat-accordion class="fullSize">
    <mat-expansion-panel *ngFor="let group of contacts | sortBy: 'asc':'name'">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ group.name }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fullSize flexTopCenter listItemBox">
        <ul class="listStyle">
          <li
            class="listItemStyle"
            *ngFor="let contact of group.contacts | sortBy: 'asc':'name'"
          >
            <a href="tel:{{ contact.number }}" class="cell_a">
              <div class="flexCenter circle">
                <img
                  class="cell_image"
                  src="assets/icons/phone_handle_icon.png"
                  alt="Phone Handle"
                />
              </div>
              <div class="box flexCenterLeft">
                <span class="listItemTextStyle">
                  {{ contact.name }}
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
