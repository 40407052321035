import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, Scroll } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { LoggingService } from "logging";
import { filter } from "rxjs/operators";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("body") bodyRef: ElementRef;

  constructor(
    private auth: AuthService,
    private router: Router,
    private logging: LoggingService
  ) {
    console.log("Instantiate router listener");
  }

  title = "MedicNow";

  scrollPosition = [];

  scrollSubscription;

  ngOnInit() {
    console.log("Last release timestamp is set to:", environment.timeStamp);
    this.scrollSubscription = this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (e.routerEvent.url === "/" || e.routerEvent.url === "/home") {
          this.scrollPosition = [];
        }

        if (e.position) {
          // backward
          setTimeout(() => {
            this.bodyRef.nativeElement.scrollTop = this.scrollPosition.pop();
          }, 10);
        } else if (e.anchor) {
          //anchor
          this.bodyRef.nativeElement.scroll(0, e.anchor);
        } else {
          // forward
          this.scrollPosition.push(this.bodyRef.nativeElement.scrollTop);
          setTimeout(() => {
            this.bodyRef.nativeElement.scrollTop = 0;
          }, 10);
        }
      });

    // window.addEventListener(
    //   "orientationchange",
    //   function () {
    //     if (window.orientation == -90) {
    //       document.getElementById("topLevel").className = "orientright";
    //     }
    //     if (window.orientation == 90) {
    // console.log("orient left");
    // document.getElementById("topLevel").className = "orientleft";
    //     }
    //     if (window.orientation == 0) {
    //       document.getElementById("topLevel").className = "";
    //     }
    //   },
    //   true
    // );
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
}
