import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Contacts } from "./contacts";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
  public contacts: Contacts[] = [];

  constructor(private http: HttpClient, private location: Location) {}

  ngOnInit(): void {
    this.http
      .get("assets/contacts.json", { responseType: "text" })
      .subscribe((data) => {
        this.contacts = <Contacts[]>JSON.parse(data);
      });
  }

  setTelephoneHref(): void {
    this.location.go("tel:6105178289");
  }
}
