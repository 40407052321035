import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"],
})
export class SupportComponent implements OnInit {
  submitted = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.submitted = false;
  }

  onSubmit(contactForm: NgForm) {
    if (contactForm.valid) {
      const email = contactForm.value;
      const headers = new HttpHeaders({ "Content-Type": "application/json" });
      this.http
        .post(
          "https://formspree.io/f/xknkvwoz",
          { name: email.name, replyto: email.email, message: email.messages },
          { headers: headers }
        )
        .subscribe((response) => {
          console.log(response);
        });

      this.submitted = true;
    }
  }
}
