import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { AuthService as MyAuthService, Role } from "auth";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { UiNotificationService } from "ui-notification";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private notificationService: UiNotificationService,
    private myAuthService: MyAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var result = this.authService.user$.pipe(
      mergeMap((user) => {
        return this.mapUserToBoolean(user, route);
      })
    );
    console.log(result);
    return result;
  }

  mapUserToBoolean(user, route): Promise<boolean> {
    let protectedRoles = route.data.roles as Array<string>;
    var promise = this.myAuthService.getUserRoles(user.sub);
    return promise
      .then((data) => {
        let mappedData = (<Role[]>data).map((x) => x.name);
        let difference = protectedRoles.filter((x) => !mappedData.includes(x));
        if (difference.length > 0) {
          this.notificationService.failureNotification(
            "You are not authorized to visit this page, you must have the role(s): " +
              protectedRoles
          );
        }
        return difference.length == 0;
      })
      .catch((err) => {
        console.log(err);
        this.notificationService.failureNotification(
          "You are not authorized to visit this page, you must have the role(s): " +
            protectedRoles
        );
        return false;
      });
  }
}
