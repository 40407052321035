<div class="fullSize flexLeftCenter flexColumn pad16LR">
  <div>
    <p class="version"><u>Version</u>: {{ version }}</p>
    <p class="version"><u>Build Timestamp</u>: {{ timestamp }}</p>
    <p class="version"><u>User Agent</u>: {{ userAgent }}</p>
  </div>
  <br />
  <br />
  <div class="flexCenter flexColumn">
    <div>
      <p class="update">Click the button below to update the MedicNow app.</p>
    </div>
    <div>
      <button mat-fab color="primary" (click)="checkForUpdate()">
        <mat-icon>update</mat-icon>
      </button>
    </div>
    <div class="pad8T">
      <p class="update">{{ postUpdateRequestMessage }}</p>
    </div>
  </div>
</div>
